.MuiButton-root {
  &.pdfButton {
    padding: 0;
    margin-right: 8px;
    border-color: #00000024;

  &:hover{
  border-color: #00000024;
  background: #01878619;
  }
  &:active{
  border-color: #017374;
  background: #01878619;
  }

    a {
      padding: 5px 15px;
      color: inherit;
      outline: none;
      text-decoration: none;
    }
  }
}
.MuiButton-root{
    &.modifyButton {
        padding: 5px 15px;
        margin-right: 8px;
        border-color: #00000024;

    &:hover{
        border-color: #00000024;
         background: #01878619;
  }
    &:active{
        border-color: #017374;
        background: #01878619;
  }
 }
}
