.Calendar {
  width: 85%;
  background-color: white;
  height: 100%;
}

.Toggles {
  display: flex;
  flex-direction: row;
}

@media screen and (max-height: 900px) {
  .calendar-filter {
    padding-top: 12px !important;
  }
}
