$color0: #444;


html, body {
    padding: 0 0;
    margin: 0 0;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: $color0;
}