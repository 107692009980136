@media screen and (max-height: 900px) {
  .empty-leave-1,
  .empty-leave-3 {
    height: 20px !important;
    min-height: 20px !important;
  }
  .empty-leave-2 {
    height: 28px !important;
    min-height: 28px !important;
  }
  .bank-holiday {
    height: 20px !important;
    min-height: 20px !important;
    padding-top: 1px !important;
  }
}
